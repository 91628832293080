body {
	background-color: #eee;
}

/*----------*\
     #LAYOUT
  \*----------*/

.temp-wrapper {
	width: 100%;
	/* margin: 20px; */
}

/*------------*\
     #IPHONE X
  \*------------*/

.px {
	position: relative;
	padding: 6% 7%;
}

.px--ls {
	padding: 3.3% 3%;
}

.px__body {
	position: absolute;
	top: 0;
	right: 1%;
	bottom: 0;
	left: 1%;
	background: linear-gradient(to top, #e5e5e5 0%, #f7f7f9 10%, #eeeef0 90%);
	border-radius: 14%/7%;
	box-shadow: inset 0 0 3px 1px #000;
}

.px--ls > .px__body {
	top: 1%;
	right: 0;
	bottom: 1%;
	left: 0;
	border-radius: 7%/14%;
}

.px__body:before {
	content: "";
	position: absolute;
	top: 0.7%;
	right: 1.4%;
	bottom: 0.7%;
	left: 1.4%;
	background-color: #000;
	border-radius: 13%/7%;
	box-shadow: 0 0 3px #000;
}

.px--ls > .px__body:before {
	top: 1.4%;
	right: 0.7%;
	bottom: 1.4%;
	left: 0.7%;
	border-radius: 7%/13%;
}

.px__body:after {
	content: "";
	position: absolute;
	top: 1%;
	right: 2.3%;
	bottom: 1%;
	left: 2.3%;
	background-color: #000;
	box-shadow: inset 0 0 10px 2px #fff;
	border-radius: 13%/6.5%;
}

.px--ls > .px__body:after {
	top: 2.3%;
	right: 1%;
	bottom: 2.3%;
	left: 1%;
	border-radius: 6.5%/13%;
}

.px__body__cut {
	position: absolute;
	z-index: 2;
	top: 3%;
	left: 50%;
	width: 50%;
	height: 3.5%;
	background-color: #000;
	border-radius: 0 0 10% 10%/80%;
	transform: translate3d(-50%, 0, 0);
}

.px--ls .px__body__cut {
	top: 50%;
	left: 3%;
	width: 3.5%;
	height: 50%;
	border-radius: 0 80% 80% 0/10%;
	transform: translate3d(0, -50%, 0);
}

.px__body__speaker,
.px__body__camera,
.px__body__sensor {
	position: absolute;
	z-index: 2;
	background-color: #222;
	transform: translate(-50%, -50%);
}

.px__body__speaker {
	top: 4%;
	left: 50%;
	width: 12%;
	height: 0.8%;
	border-radius: 5px;
}

.px--ls .px__body__speaker {
	top: 50%;
	left: 4%;
	width: 0.8%;
	height: 12%;
}

.px__body__camera {
	top: 4%;
	left: 36%;
	width: 3.5%;
	height: 1.7%;
	border-radius: 50%;
	box-shadow: inset 0 0 2px 1px #000;
}

.px--ls .px__body__camera {
	top: 64%;
	left: 4%;
	width: 1.7%;
	height: 3.5%;
}

.px__body__sensor {
	top: 4%;
	left: 61%;
	width: 2.2%;
	height: 1.1%;
	border-radius: 50%;
	background-color: #2a4a73;
	box-shadow: inset 0 0 2px 1px #000;
}

.px--ls .px__body__sensor {
	top: 39%;
	left: 4%;
	width: 1.1%;
	height: 2.2%;
}

.px__body__mute,
.px__body__up,
.px__body__down,
.px__body__right {
	position: absolute;
	background-color: #b5b8ba;
	box-shadow: inset 0 5px 5px -3px rgba(0, 0, 0, 0.5),
		inset 0 -5px 5px -3px rgba(0, 0, 0, 0.5);
	transform: translate(0, -50%);
}

.px--ls .px__body__mute,
.px--ls .px__body__up,
.px--ls .px__body__down,
.px--ls .px__body__right {
	box-shadow: inset 5px 0 5px -3px rgba(0, 0, 0, 0.5),
		inset -5px 0 5px -3px rgba(0, 0, 0, 0.5);
	transform: translate(-50%, 0);
}

.px--ls .px__body__right {
	background-color: #f4f6f6;
}

.px__body__mute {
	top: 14.7%;
	left: -0.7%;
	width: 0.7%;
	height: 4%;
	border-radius: 1px 0 0 1px;
}

.px--ls .px__body__mute {
	top: auto;
	left: 14.7%;
	bottom: -0.7%;
	width: 4%;
	height: 0.7%;
	border-radius: 0 0 1px 1px;
}

.px__body__up,
.px__body__down {
	left: -1%;
	width: 1%;
	height: 7.5%;
	border-radius: 2px 0 0 2px;
}

.px--ls .px__body__up,
.px--ls .px__body__down {
	bottom: -1%;
	width: 7.5%;
	height: 1%;
	border-radius: 0 0 2px 2px;
}

.px__body__up {
	top: 25%;
}

.px--ls .px__body__up {
	top: auto;
	left: 25%;
}

.px__body__down {
	top: 34%;
}

.px--ls .px__body__down {
	top: auto;
	left: 34%;
}

.px__body__right {
	top: 25%;
	right: -1%;
	width: 1%;
	height: 7.5%;
	border-radius: 0 2px 2px 0;
}

.px--ls .px__body__right {
	top: -1%;
	right: auto;
	left: 25%;
	width: 7.5%;
	height: 1%;
	border-radius: 2px 2px 0 0;
}

.px__screen {
	position: relative;
	z-index: 1;
}

.px__screen__image-wrapper {
	width: 50%;
	margin: 0 auto;
	display: flex;
	justify-content: center;
}

.px__screen__ {
	position: relative;
	padding-bottom: 218%;
	background-color: #888;
	border-radius: 10%/5%;
	box-shadow: 0 0 10px #000;
	background: #fff;
}

.px--ls .px__screen__ {
	padding-bottom: 46%;
	border-radius: 5%/10%;
}

.px__screen__frame {
	position: absolute;
	top: 40px;
	right: 0;
	bottom: 45px;
	left: 0;
	overflow: hidden;
	border-radius: inherit;
	background-size: cover;
	background-position: center center;
	max-height: 100%;
	overflow: auto;
}

.px__screen__frame::-webkit-scrollbar {
	width: 15px;
	color: red;
	background-color: red;
	display: none;
}

.px__screen__frame::-webkit-scrollbar-track {
	background: #f1f1f1;
}

.px__screen__frame > .fa {
	position: absolute;
	top: 50%;
	left: 50%;
	font-size: 5em;
	transform: translate(-50%, -50%);
}

.px__screen_logo {
	margin: 24px;
	height: 85px;
}

.slick_slider {
	width: 100%;
}

.slick_slider .slide {
	width: 400px !important;
	max-height: 365px;
	outline: none;
	position: relative;
}

.slick_slider .slide img {
	width: 90%;
	height: 50%;
}
.mobile_layout_tabs {
	justify-content: center;
}

.mobile_layout_tabs .nav-link {
	color: #ccc !important;
}

.mobile_layout_tabs .nav-link.active {
	border: none !important;
	border-bottom: 3px solid lightgreen !important;
	color: #000 !important;
}

.test {
	background-color: #2c2080;
	color: #fff;
	position: absolute;
	top: 33%;
	right: 0;
	padding: 12px;
	border-radius: 12px 0 0 12px;
}

.article img {
	border-radius: 10px !important;
}

.overlay {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	height: 50%;
	border-radius: 10px;
	width: 90%;
	display: none;
	background-color: #000;
	opacity: 0.8;
}

.overlay .text {
	color: #fff;
	text-align: center;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	font-size: 17px;
	font-weight: bold;
}

/* @media all and (min-width: 480px) {
	.temp-wrapper {
		width: 80%;
		margin: 20px auto;
	}

	.temp-wrapper--wide {
		width: 100%;
	}

	.temp-wrapper--wider {
		width: 100%;
	}
	.slick_slider .slide {
		width: 230px !important;
		border-radius: 10px;
	}
	.slick_slider .slide img {
		border-radius: 10px;
	}
}

@media all and (min-width: 768px) {
	.temp-wrapper {
		width: 80%;
	}

	.temp-wrapper--wide {
		width: 66.66667%;
	}

	.temp-wrapper--wider {
		width: 100%;
	}

	.slick_slider .slide {
		width: 280px !important;
	}
}

@media all and (min-width: 1024px) {
	.temp-wrapper {
		width: 50%;
	}

	.temp-wrapper--wide {
		width: 50%;
	}

	.temp-wrapper--wider {
		width: 75%;
	}
	.slick_slider .slide {
		width: 300px !important;
	}
}

@media all and (min-width: 1368px) {
	.temp-wrapper {
		width: 50%;
	}
} */
