@font-face {
  font-family: nulshock;
  src: url("../fonts/nulshock/nulshock bd.ttf");
}

@font-face {
  font-family: walsheim;
  src: url("../fonts/GT Walsheim/GTWalsheimPro-Regular.ttf");
}

.container {
  max-width: 1170px;
  margin: auto;
}

/* .rdt_TableBody {
  overflow: visible !important;
}

	.rdt_Table  {
		overflow: visible !important;
	} */
	.rdt_TableBody {
		overflow: visible !important;
    /* display: none !important; */
	}

.content {
  /*position: relative;*/
  z-index: 1;
}

img {
  max-width: 100%;
}

.sidebar-toggler {
  position: absolute;
  top: 10px;
}

.sidebar-brand-text {
  font-family: nulshock;
  font-size: 23px;
}

.nav-item.active {
  background: #3ca6f2;
}

.polygon {
  height: 100px;
  width: 100px;
  background: #f3f9fc;
  position: absolute;
  opacity: 0.2;
  clip-path: ellipse(63px 64px at 10% 20%);
  margin-bottom: 30px;
}

.inbox_people {
  background: #f8f8f8 none repeat scroll 0 0;
  float: left;
  overflow: auto;
  width: 34%;
  border-radius: 10px;
  background: #fff;
  min-height: 845px;
  margin-left: 30px;
  box-shadow: 5px 5px 10px #f4f3f3;
  font-family: walsheim;
}

.inbox_msg {
  clear: both;
  overflow: hidden;
}

.top_spac {
  margin: 20px 0 0;
}

.recent_heading {
  float: left;
  width: 40%;
}
.srch_bar {
  display: inline-block;
  text-align: right;
  width: 60%;
  padding: 0px;
}

.heading_srch {
  margin: 10px 0 10px 0;
}

.recent_heading h4 {
  color: #05728f;
  font-size: 21px;
  margin: auto;
}

.srch_bar input {
  border: 1px solid #cdcdcd;
  border-width: 0 0 1px 0;
  width: 80%;
  padding: 2px 0 4px 6px;
  background: none;
}
.srch_bar .input-group-addon button {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: medium none;
  padding: 0;
  color: #707070;
  font-size: 18px;
}
.srch_bar .input-group-addon {
  margin: 0 0 0 -27px;
}

.chat_ib h5 {
  font-size: 15px;
  color: #464646;
  margin: 0 0 8px 0;
}
.chat_ib h5 span {
  font-size: 13px;
  float: right;
}
.chat_ib p {
  font-size: 14px;
  color: #989898;
  margin: auto;
}
.chat_img {
  float: left;
  width: 11%;
  position: relative;
}
.chat_ib {
  float: left;
  padding: 0 0 0 15px;
  width: 88%;
}

.online-status {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  position: absolute;
  top: 0%;
  right: 0%;
  background-color: #00c502;
}

.chat_people {
  overflow: hidden;
  clear: both;
}
.chat_list {
  position: relative;
  border-bottom: 1px solid #c4c4c4;
  margin: 0;
  padding: 30px 19px 30px 10px;
}

.chat_list.active_chat:after {
  content: "";
  height: 85px;
  width: 9px;
  display: block;
  top: 27px;
  position: absolute;
  right: 0;
  background: #1188ea;
}

.inbox_chat {
  height: 550px;
  overflow: auto;
  background: #fff;
}

.active_chat {
  background: #f3f9fc;
}

.msg {
  text-align: left;
}

.incoming_msg_img {
  display: inline-block;
  width: 6%;
}

.received_msg {
  display: inline-block;
  padding: 0 0 0 10px;
  vertical-align: top;
  width: 92%;
}

.received_withd_msg p {
  background: #f3f9fc;
  border-radius: 3px;
  color: #646464;
  font-size: 14px;
  margin: 0;
  padding: 10px;
  width: 100%;
  text-align: left;
}

.time_date {
  color: #747474;
  display: block;
  font-size: 12px;
  margin: 8px 0 0;
}

.received_withd_msg {
  width: 75%;
}
.messages {
  float: left;
  padding: 10px;
  width: 57%;
  border-radius: 10px;
  margin-left: 20px;
  padding: 20px;
  background: #fff;
  min-height: 845px;
  box-shadow: 5px 5px 10px #f4f3f3;
  overflow: auto;
  font-family: walsheim;
}

.sent_msg p {
  background: #f8f8f8 none repeat scroll 0 0;
  border-radius: 3px;
  font-size: 14px;
  margin: 0;
  color: #fff;
  padding: 10px;
  width: 100%;
  color: #000;
  text-align: right;
}

.outgoing_msg {
  overflow: hidden;
  margin: 26px 0 26px;
}

.dkdjf_23 {
  width: 75%;
  float: right;
}
.sent_msg {
  float: right;
  width: 75%;
}

.input_msg_write {
  position: relative;
  border: 1px solid #ecebeb;
  border-radius: 5px;
}

.input_msg_write input {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: medium none;
  color: #4c4c4c;
  font-size: 15px;
  min-height: 48px;
  width: 100%;
  padding: 25px;
}

.type_msg {
  background: #f8f8f8;
  padding: 20px;
  position: relative;
}
.msg_send_btn {
  background: #30a0f3 none repeat scroll 0 0;
  border: medium none;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  font-size: 17px;
  position: absolute;
  right: 10px;
  top: 20px;
  width: 33px;
  height: 40px;
  width: 40px;
}

.msg_send_btn i {
  font-size: 15px;
}

.messaging {
  padding: 0 0 50px 0;
}
.msg_history {
  height: 516px;
  overflow-y: auto;
  margin-bottom: 5rem;
  border: 1px solid #ecebeb;
  padding: 10px;
  border-radius: 10px;
}

.user_img {
  border-radius: 50%;
  height: 37px;
  width: 100%;
}

.messages_user_img {
  height: 33px;
}

.brand {
  color: #fff;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Lato", "Arial", sans-serif;
}

/* HEADINGS */

h1 {
  color: #fff;
  text-align: center;
  line-height: 1.4;
}

h1 {
  font-size: 2.2rem;
}

h2 {
  color: #000;
  font-size: 1.3rem;
  text-align: center;
  line-height: 1.4;
  margin-bottom: 10px;
}

/* BASIC SETUP */

.page-wrapper {
  width: 100%;
  height: auto;
}

.nav-wrapper {
  width: 100%;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  background-color: #fff;
}

.grad-bar {
  width: 100%;
  height: 5px;
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  -webkit-animation: gradbar 15s ease infinite;
  -moz-animation: gradbar 15s ease infinite;
  animation: gradbar 15s ease infinite;
}

/* NAVIGATION */

/* SECTIONS */

.headline {
  width: 100%;
  height: 50vh;
  min-height: 350px;
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.7)),
    url("https://d2v9y0dukr6mq2.cloudfront.net/video/thumbnail/jPLiQ-9/mountains-background-for-titles-intro-projects-and-etc_ew22rur3l__F0000.png");
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.features {
  width: 100%;
  height: auto;
  background-color: #f1f1f1;
  display: flex;
  padding: 50px 20px;
  justify-content: space-around;
}

.feature-container {
  flex-basis: 30%;
  margin-top: 10px;
}

.feature-container p {
  color: #000;
  text-align: center;
  line-height: 1.4;
  margin-bottom: 15px;
}

.feature-container img {
  width: 100%;
  margin-bottom: 15px;
}

/* SEARCH FUNCTION */

.search {
  transform: translate(-35%);
  -webkit-transform: translate(-35%);
  transition: transform 0.7s ease-in-out;
  color: #3498db;
}

.no-search {
  transform: translate(0);
  transition: transform 0.7s ease-in-out;
}

input {
  border: 0;
  border-left: 1px solid #ccc;
  border-radius: 0; /* FOR SAFARI */
  /* outline: 0; */
  padding: 5px;
}

/* MOBILE MENU & ANIMATION */

.menu-toggle .bar {
  width: 25px;
  height: 3px;
  background-color: #3f3f3f;
  margin: 5px auto;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.menu-toggle {
  justify-self: end;
  margin-right: 25px;
  display: none;
}

.menu-toggle:hover {
  cursor: pointer;
}

.sidebar-toggler {
  position: absolute;
  top: 10px;
}

#mobile-menu.is-active .bar:nth-child(2) {
  opacity: 0;
}

#mobile-menu.is-active .bar:nth-child(1) {
  -webkit-transform: translateY(8px) rotate(45deg);
  -ms-transform: translateY(8px) rotate(45deg);
  -o-transform: translateY(8px) rotate(45deg);
  transform: translateY(8px) rotate(45deg);
}

#mobile-menu.is-active .bar:nth-child(3) {
  -webkit-transform: translateY(-8px) rotate(-45deg);
  -ms-transform: translateY(-8px) rotate(-45deg);
  -o-transform: translateY(-8px) rotate(-45deg);
  transform: translateY(-8px) rotate(-45deg);
}

.header_23 {
  width: calc(100% - 11rem) !important;
}

.cp_hc {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.select2-container {
  width: 100% !important;
}

.select2-container--default .select2-selection--single {
  height: 45px !important;
  padding: 8px;
  border: 1px solid #ecebeb !important;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 40px !important;
  padding: 5px 30px 5px 5px;
}

.iblist_header_icon {
  padding: 10px;
  border: 1px solid #ecebeb;
  background-color: #fff;
  border-radius: 5px;
  width: 45px;
}

.heading_srch_filters_cont {
  text-align: right;
}

img.rounded-circle {
  height: 2rem;
  width: 2rem;
}

.img-profile {
  height: 2.7rem;
  width: 2.7rem;
  border-radius: 5px;
}

.dropdown-list {
  width: 20rem;
}

.dropdown-item span,
div {
  white-space: normal;
}

.dropdown-list {
  overflow: hidden;
}

.bg-light {
  background: #fff;
}

.btn-light-blue {
  border: none;
  outline: none;
  background: #f3f9fc;
  text-align: center;
  padding: 10px;
  color: #5aa4f3;
  border-radius: 5px;
  min-width: 170px;
  font-size: 15px;
}

.btn-transparent {
  border: none;
  outline: none;
  background: transparent;
  border: 1px solid #ecebeb;
  text-align: center;
  padding: 10px;
  color: #98999b;
  border-radius: 5px;
  min-width: 150px;
  font-size: 15px;
}

.btn-blue {
  border: none;
  outline: none;
  background: #0f92f1;
  border: none;
  text-align: center;
  padding: 10px;
  color: #f8f8f8;
  border-radius: 5px;
  min-width: 150px;
  font-size: 15px;
}

.bookmark_chat_icon {
  color: #ccc;
  font-size: 12px;
}

.msg_info_users_icon {
  border: none;
  background: #f3f9fc;
  border-radius: 5px;
  padding: 10px;
  color: #0f92f1;
}

.msg_info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.mobile {
  margin: 10px 30px 0 10px;
  font-weight: bold;
}

.topbar-icon {
  height: 45px;
  width: 45px;
  border: 1px solid #ecebeb;
  padding: 10px;
  text-align: center;
  border-radius: 5px;
}

.topbar_dots_container {
  background: #0f92f1;
  padding: 3px;
  border-radius: 5px;
  width: 20px;
}

.dot {
  background: #fff;
  height: 5px;
  width: 5px;
  border-radius: 50%;
  margin-top: 3px;
}
.notification-badge {
  position: absolute;
  top: 5px;
  left: 20px;
  font-size: 65% !important;
}

.dkfj_34 {
  margin-top: 0px;
}

.has-search .form-control {
  padding-left: 2.375rem;
  width: 100%;
  height: 3rem;
  background: #f9f9f9;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 3rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
}

.user-info-panel {
  width: 400px;
  height: 100vh;
  position: absolute;
  right: -320px;
  background: #fff;
  color: #000;
  padding: 20px;
  box-shadow: 5px 5px 10px #ecebeb;
  min-height: 845px;
  overflow: auto;
}

.ico {
  border: none;
  border-radius: 5px;
  padding: 12px;
  height: 40px;
  width: 40px;
}

.whatsapp_ico {
  background: #e9faea;
  color: #00c502;
}

.comment_ico {
  background: #f3f9fc;
  color: #0f92f1;
}

.user_basic_info {
  color: #000;
}

.text-silver {
  color: #c2c4c5;
}

.add-note {
  font-weight: 600;
}

.msg_history {
  padding: 25px;
}

.panel-user-img {
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
}

.message-util-icon {
  flex-basis: 50px;
}

/* KEYFRAME ANIMATIONS */

@-webkit-keyframes gradbar {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@-moz-keyframes gradbar {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes gradbar {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* Media Queries */

/* Mobile Devices - Phones/Tablets */

@media screen and (max-width: 1150px) {
  .msg_info {
    flex-direction: column;
  }
  .dkfj_34 {
    margin-top: 10px;
  }

  .user-info-panel {
    left: 30px;
    width: 93%;
  }

  #toggle_user_info_panel {
    display: none;
  }
}

@media screen and (max-width: 992px) {
  .iblist_header_icon {
    padding: 10px 0 10px 0;
    width: 40px;
  }
  .cp_hc {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .inbox_people {
    width: 40%;
  }
  .messages {
    margin-left: 10px;
    padding: 5px;
    width: 52%;
  }
  .heading_srch_filters_cont {
    padding-left: 0 !important;
  }
  .menu-item {
    width: 32%;
    margin-top: 20px;
  }
  .top-navbar {
    margin-top: 20px;
  }
  .chat_list {
    padding: 14px 13px 14px 7px;
  }
  .chat_list.active_chat:after {
    height: 49px;
    width: 8px;
    top: 27px;
  }
}

@media screen and (max-width: 768px) {
  .iblist_header_icon {
    width: 45px;
  }

  .inbox_people {
    margin-left: 18px;
  }

  .messages {
    width: 52%;
  }

  .select2-container {
    width: 90% !important;
  }

  .iblist_header_icon {
    width: 38px;
  }

  .msg_history {
    padding: 5px;
  }
}

@media only screen and (max-width: 720px) {
  .features {
    flex-direction: column;
    padding: 50px;
  }

  /* MOBILE HEADINGS */

  h1 {
    font-size: 1.9rem;
  }

  h2 {
    font-size: 1rem;
  }

  p {
    font-size: 0.8rem;
  }

  /* MOBILE NAVIGATION */

  /* SECTIONS */

  .headline {
    height: 20vh;
  }

  .feature-container p {
    margin-bottom: 25px;
  }

  .feature-container {
    margin-top: 20px;
  }

  .feature-container:nth-child(2) {
    order: -1;
  }

  /* SEARCH DISABLED ON MOBILE */
}
